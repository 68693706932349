.icon {
    font-size: 1.2rem;
    margin-left: 1rem;
    display: inline-block !important;
    transform: scale(1) translateY(0px);
    transition: 0.5s;
}

.icon:hover {
    transform: scale(1.3) translateY(10px);;
}

.icon path {
    color: var(--secondary);
}

.span {
    font-size: 0.6rem;
    direction: ltr;
    unicode-bidi: embed;
    margin-right: 0.5rem
}
.span a{
    font-size: 0.6rem;
    margin-right: 0.5rem;
}

.copyright {
    font-weight: 400;
    font-size: 0.9rem;
}

.madeBy {
    color: #0c0e3a;
}

.link svg {
    margin-left: 1rem;
    font-size: 1rem
}

.socialMedia {
    justify-content: end;
    align-items: center;
}
.phone{
    align-items: center;
}


@media only screen and (max-width: 600px) {
    .icon {
        font-size: 0.7rem;
        margin-left: 0rem;
    }
    .span{
        font-size: 0.5rem;
    }
    .span a{
        margin-right: 0.5rem;
        font-size: 0.5rem !important;
    }

    .copyRightFooter {
        flex-direction: column-reverse;
    }

    .start, .end {
        display: flex;
        justify-content: center !important;
    }

    .row{
        flex-direction: column !important;
    }
    .socialMedia{
        margin-top: 1rem !important;
        justify-content: center;
    }
    .phone{
        display: flex;
        justify-content: center;
    }
}