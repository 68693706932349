.resultBox .ant-result-icon{
    text-align: center;
}

.resultBox .ant-result-title{
    text-align: center;
    margin: 2rem;
}

.resultBox .ant-result-subtitle{
    margin: 1rem;
}