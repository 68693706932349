.h2{
    text-align: center;
    color: var(--secondary);
    font-weight: bold;
    font-size: 1.4rem;
    margin-bottom:2rem
}
.coloredBox{
    width: 250px !important;
    height: 250px;
    margin: 0.5rem 0;
}
.coloredBox:hover{
    transform: scale(1.1);
    cursor: pointer
}
.mgL{
    margin-left: 0 !important;
}
.mgR{
    margin-right: 0 !important;
}
.img{
    width: 125px;
}

@media only screen and (max-width:600px){
    .img{
        width: 75px !important;
    }
    .title{
        font-size:0.6rem
    }
    .col{
        width: 50% !important;
    }
    .coloredBox{
        width: 150px !important;
        height: 150px !important;
    }
    .h2{
        font-size: 1rem;
    }
}
