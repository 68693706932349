:root {
  --primary: rgb(255, 129, 138);
  --secondary: #662D8E;
  --text: #7D93B2;
  --box-color: #EBF0F8;
  --bg-color: #FAFCFF;
  --white: white;
}

html {
  overflow: hidden;
}

* {
  box-sizing: border-box;
  font-size: 18px;
  padding: 0;
  margin: 0;
  font-family: 'Noto Sans Arabic', sans-serif;
  font-weight: 600;
  /*color: var(--text);*/
  transition: all 0.5s !important;
}

#root {
  display: flex;
  flex-flow: column;
  height: 100%;
}

header {
  flex: 0 1 auto;
}

main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
}

footer {
  flex: 0 1 auto;
}

body {
  background-color: var(--bg-color);
  background-image: url('./images/bg-pattern.svg');
  background-position: top;
  background-size: cover;
  background-repeat: repeat;
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
}

a {
  text-decoration: none;
  color: var(--secondary);
  transition: all 0.5s !important;
  transform: scale(1);
  display: inline-block;
}

a:hover {
  color: var(--primary);
  transform: scale(1.1);
}

.start {
  display: flex;
  justify-content: start;
}

.end {
  display: flex;
  justify-content: end;
}

.btn {
  border: 1px solid;
  border-radius: 50px;
  outline: none !important;
  box-shadow: none;
  font-weight: 700;
}

.btn-primary {
  background: var(--primary);
  border-color: var(--primary);
}

.btn-outline-primary {
  background-color: transparent;
  color: var(--secondary);
  border-color: var(--primary);
}

.btn-outline-primary:hover, .btn-outline-primary:active, .btn-outline-primary:focus {
  background: var(--primary);
  border-color: var(--primary);
  color: var(--white);
}

.btn-primary:hover, .btn-primary:focus {
  background-color: transparent;
  color: var(--primary);
  border: 1px solid var(--primary);
  outline: none !important;
  box-shadow: none;
}

.btn:focus, .btn:active {
  outline: none !important;
  box-shadow: none;
}

.btn:disabled {
  background: var(--primary) !important;
  border-color: var(--primary) !important;
}

.ant-btn:focus, .ant-btn:active {
  background-color: var(--secondary) !important;
  color: white !important;
  border-color: var(--secondary) !important;
}

.ant-btn-primary {
  color: white !important;
}
.ant-result-title{
  direction: ltr;
}
.right-title-direction .ant-result-title{
  direction: rtl !important;
}