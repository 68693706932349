.header{
    margin-top: 2rem;
}

.c-nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
}

.logo {
    height: 3rem;
    display: inline-block;
    align-items: center;

}
.btn{
    font-size: 1rem;
    margin: auto 1rem;
}

@media only screen and (max-width: 600px) {
    .btn{
        font-size: 0.6rem;
    }
}