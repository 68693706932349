.h2{
    text-align: center;
    color: var(--secondary);
    font-weight: bold;
    font-size: 1.4rem;
    margin-bottom:3rem
}
.row{
    column-gap: 3rem;
}
.coloredBox{
    width: 300px !important;
    height: 300px;
    margin: 0.5rem auto;
}
.coloredBox:hover{
    transform: scale(1.1);
    cursor: pointer
}
.tLeft{
    text-align: left;
}
.tRight{
    text-align: right;
}
@media only screen and (max-width: 600px){
    .col{
        width: 50% !important;
        flex: 0 0 50% !important;
    }
    .row{
        column-gap: 0;
    }
    .coloredBox{
        width: 150px !important;
        height: 150px !important;
    }
    .babyCol{
        display: flex;
    }
}