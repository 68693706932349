.form-box {
    width: 40vw !important;
    height: 600px !important;
    min-height: 400px;
    margin-top: 3rem !important;
    justify-content: center;
    padding: 3rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.form-box .main-row {
    align-items: center;
    justify-content: center;
    width: 80%;
}

.form-box .main-row input {
    height: 3rem;
    width: 80%;
    border-radius: 1rem;
    border: none;
}

.form-box .main-row input:focus,.form-box .main-row input:active,.main-row input:hover{
    border-color: red !important;
}
.form-box button {
    width: 25%;
    height: 3rem;
    border-radius: 1rem;
    background-color: var(--primary);
    border-color: var(--primary);
    margin-top: 2rem;
    border-style: solid;
}

.form-box button:hover {
    background-color: transparent;
    border-color: var(--primary);
    transform: scale(1.1);
}

.form-box button:hover span {
    color: var(--primary);
    text-shadow: none;
}

.form-box button span {
    color: white;
    font-size: 1rem;
}

@media only screen and (max-width: 600px) {
    .form-box {
        width: 95% !important;
        margin-top: 0 !important;
        margin-bottom: 5rem;
    }

    .form-box .form-label {
        font-size: 1rem !important;
    }

    .form-box button {
        width: 50%;
    }
}