.box {
    justify-content: center;
    padding: 3rem;
    align-items: center;
    border: 1rem solid transparent;
    flex-direction: column;
}

.title {
    font-size: 1.4rem;
    font-weight: bold;
    color: var(--secondary);
}
.right {
    direction: rtl !important;
}
.row {
    text-align: center;
    align-self: stretch;
}
.icon svg{
    width:4rem;
    height:4rem;
    color: var(--secondary);
}
.greenBorder{
    border-color: #7aa94a;
}
.blueBorder{
    border-color: #2980b9;
}
.redBorder{
    border-color: #e74c3c;
}
.green svg{
    color: #7aa94a;
}
.blue svg{
    color: #2980b9;
}
.red svg{
    color: #e74c3c;
}
.btn{
    margin-right: 1rem !important;
    background-color: var(--secondary) !important;
    color: white !important;
}

@media only screen and (max-width:600px){
    .parg{
        text-align: center;
    }
    .btnAnchot{
        display: block;
        text-align: center;
        margin-top: 2rem !important;
    }
}
