.box {
    min-height: 60vh;
    width: 100%;
    background-color: var(--box-color);
    border-radius: 50px;
    display: flex;
    align-items: stretch;
}

@media (min-width: 1200px) {
    .box{

    }
}